import { Route } from '@angular/router'
import {
  ACCOUNTING_ADMIN,
  canActivateAuthRole,
  COMPANY_ADMIN,
  CUSTOMER_ADMIN,
  FEEDBACK_ADMIN,
  INGEST_ADMIN,
  NotAuthorizedComponent,
  PageNotFoundComponent,
  SALES_ADMIN,
  SEARCH_ADMIN,
  STOCKLEVEL_IMPORT,
  THING_TEMPLATE_ADMIN
} from "@mybitzer-workspace/shared"

export const appRoutes: Route[] = [
  {
    path: '',
    title: 'MyBITZER Back Office',
    loadComponent: () => import('../home/home.component').then(m => m.HomeComponent),
    data: { roles: [SEARCH_ADMIN, FEEDBACK_ADMIN, SALES_ADMIN, COMPANY_ADMIN, CUSTOMER_ADMIN, ACCOUNTING_ADMIN, STOCKLEVEL_IMPORT] },
    canActivate: [canActivateAuthRole],
    children: [
      {
        path: '',
        redirectTo: 'search',
        pathMatch: 'full'
      },
      {
        path: 'search',
        title: 'MyBITZER Back Office - Search',
        data: { roles: [SEARCH_ADMIN] },
        canActivate: [canActivateAuthRole],
        loadChildren: () => import('../core/search/search.routes').then(m => m.routes),
      },
      {
        path: 'sales',
        title: 'MyBITZER Back Office - Sales',
        data: { roles: [SALES_ADMIN] },
        canActivate: [canActivateAuthRole],
        loadChildren: () => import('../core/sales/sales.routes').then(m => m.routes),
      },
      {
        path: 'feedback',
        title: 'MyBITZER Back Office - Feedback',
        data: { roles: [FEEDBACK_ADMIN] },
        canActivate: [canActivateAuthRole],
        loadChildren: () => import('../core/feedback/feedback.routes').then(m => m.routes),
      },
      {
        path: 'accounting',
        title: 'MyBITZER Back Office - Accounting',
        data: { roles: [ACCOUNTING_ADMIN] },
        canActivate: [canActivateAuthRole],
        loadChildren: () => import('../core/accounting/accounting.routes').then(m => m.routes),
      },
      {
        path: 'company',
        title: 'MyBITZER Back Office - Company',
        data: { roles: [COMPANY_ADMIN] },
        canActivate: [canActivateAuthRole],
        loadChildren: () => import('../core/company/company.routes').then(m => m.routes),
      },
      {
        path: 'customer',
        title: 'MyBITZER Back Office - Customer',
        data: { roles: [CUSTOMER_ADMIN] },
        canActivate: [canActivateAuthRole],
        loadChildren: () => import('../core/customer/customer.routes').then(m => m.routes),
      },
      {
        path: 'stock-level',
        title: 'MyBITZER Back Office - StockLevel',
        data: { roles: [STOCKLEVEL_IMPORT] },
        canActivate: [canActivateAuthRole],
        loadChildren: () => import('../core/stock-level/stock-level.routes').then(m => m.routes),
      },
      {
        path: 'ingest',
        title: 'MyBITZER Back Office - Ingest',
        data: { roles: [INGEST_ADMIN] },
        canActivate: [canActivateAuthRole],
        loadChildren: () => import('../core/ingest/ingest.routes').then(m => m.routes),
      },
      {
        path: 'thing-template',
        title: 'MyBITZER Back Office - Thing',
        data: { roles: [THING_TEMPLATE_ADMIN] },
        canActivate: [canActivateAuthRole],
        loadChildren: () => import('../core/thing-template/thing-template.routes').then(m => m.routes),
      },
      {
        path: 'profile',
        title: 'Profile',
        canActivate: [canActivateAuthRole],
        loadChildren: () => import('../home').then(c => c.HOME_ROUTES)
      },
    ],

  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
]
