import {ChangeDetectionStrategy, Component, input, output, signal} from '@angular/core';
import {debounceTime, filter, Subject} from 'rxjs';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {TranslatePipe} from '@ngx-translate/core';
import {MatInput} from '@angular/material/input';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatTableModule} from '@angular/material/table';

@Component({
  selector: 'boffice-search-form',
  imports: [
    MatFormFieldModule,
    TranslatePipe,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinner,
    MatTableModule
  ],
  templateUrl: './search-form.component.html',
  styleUrl: './search-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFormComponent {
  searchEvent = output<string>();
  inputText = new FormControl('')

  searching = signal(false)
  private readonly searchChangeSubject = new Subject<string>();

  constructor() {
    this.searchChangeSubject.pipe(
      filter(Boolean),
      debounceTime(500),
      takeUntilDestroyed(),
    ).subscribe(text => this.search(text))
  }

  onSearchChange(value: string) {
    this.searchChangeSubject.next(value)
  }

  search(value: string) {
    this.searchEvent.emit(value)
  }
}
