import { Component } from '@angular/core'
import { RouterModule } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { environment } from '../environments/environment'
import { Settings } from 'luxon'

@Component({
  imports: [RouterModule],
  selector: 'boffice-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'mybitzer-backoffice';
  lang: string | undefined

  constructor(private readonly translate: TranslateService) {
    this.initLang()
  }

  initLang() {
    this.lang = this.translate.getBrowserLang()
    const availableLanguages = environment.availableLanguages.map(lang => lang.code)
    this.translate.addLangs(availableLanguages)
    if (this.lang && availableLanguages.includes(this.lang)) {
      this.translate.setDefaultLang(this.lang)
      this.translate.use(this.lang)
      Settings.defaultLocale = this.lang
    } else {
      this.translate.setDefaultLang('en')
      this.translate.use('en')
      Settings.defaultLocale = 'en'
    }
  }
}
