import { Directive } from "@angular/core";


@Directive({ selector: 'boffice-header-toolbar-actions' })
export class HeaderToolbarActions {
}

@Directive({ selector: 'boffice-header-toolbar-content' })
export class HeaderToolbarContent {
}

@Directive({ selector: 'boffice-header-toolbar-icon' })
export class HeaderToolbarIcon {
}

@Directive({ selector: 'boffice-header-toolbar-filter' })
export class HeaderToolbarFilter {
}
