import { ApplicationConfig, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { includeBearerTokenInterceptor } from "keycloak-angular";
import { provideKeycloakAngular } from "./keycloak.config";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from "@angular/material/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { FullscreenOverlayContainer, OverlayContainer } from "@angular/cdk/overlay";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { registerLocaleData } from "@angular/common";
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { provideTranslateConfig } from "./translate.config";
import { provideToastConfig } from "./hot-toast.config";
import { provideEchartsConfig } from "./echarts.config";
import { provideServiceConfig } from "./service.config";
import { MatPaginatorI18nService } from "@mybitzer-workspace/shared";


registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    provideNativeDateAdapter(),
    provideToastConfig(),
    provideEchartsConfig(),
    provideServiceConfig(),
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    provideKeycloakAngular(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(withInterceptors([includeBearerTokenInterceptor])),
    provideTranslateConfig(),
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    },
    provideRouter(appRoutes),
  ],
};
