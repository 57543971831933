<mat-form-field class="search flex-auto dense-1" appearance="outline" subscriptSizing="dynamic">
  <mat-label class="search-label">{{ 'action.search' | translate }}</mat-label>
  <input matInput type="string"
         (keyup)="onSearchChange(searchText.value)"
         (keydown.enter)="search(searchText.value)"
         #searchText [formControl]="inputText"
         [disabled]="searching()">
  @if (searching()) {
    <mat-spinner matSuffix [diameter]="24" color="primary" class="pe-5"></mat-spinner>
  }
  <ng-content></ng-content>
</mat-form-field>
