import {
  createInterceptorCondition,
  INCLUDE_BEARER_TOKEN_INTERCEPTOR_CONFIG,
  IncludeBearerTokenCondition,
  provideKeycloak,
  AutoRefreshTokenService, UserActivityService, withAutoRefreshToken
} from 'keycloak-angular'
import { environment } from "../environments/environment"

const regex = /^(https?:\/\/[^/]+)?api(?:\/.*)?$/i

const condition = createInterceptorCondition<IncludeBearerTokenCondition>({
  // urlPattern:  /^(https?:\/\/[^\/]+)?\/api.*$/,
  // urlPattern:  /^.*$/,
  // urlPattern:  /^(https?:\/\/[^\/]+)?\/api\/.*$/,
  urlPattern: regex,
  bearerPrefix: 'Bearer'
})

export const provideKeycloakAngular = () =>
  provideKeycloak({
    config: environment.keycloak,
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
    },
    features: [
      withAutoRefreshToken({
        onInactivityTimeout: 'none'
      })
    ],
    providers: [
      AutoRefreshTokenService,
      UserActivityService,
      {
        provide: INCLUDE_BEARER_TOKEN_INTERCEPTOR_CONFIG,
        useValue: [condition]
      }
    ]
  })
