<input class="file-input" type="file" accept="*/*" (change)="onFileSelected($event)" #fileUpload>

<div class="file-upload flex flex-row gap-5 items-center flex-auto px-4 py-2">
  <div class="basis-1/4">
    @if (uploading) {
      @if (uploading) {
        <mat-progress-bar class="progress-bar" mode="determinate"></mat-progress-bar>
      }
    } @else {
      {{ fileName || ('file-upload.error.nofile' | translate) }}
    }
  </div>
  <button mat-fab extended color="primary" class="upload-btn" (click)="fileUpload.click()" [disabled]="uploading">
    <mat-icon>attach_file</mat-icon>
    {{ 'file-upload.action.upload' | translate }}
  </button>


</div>
