import { Component } from '@angular/core';
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { HotToastService } from "@ngxpert/hot-toast";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { EMPTY, interval, switchMap, tap } from "rxjs";
import { TranslatePipe } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatDivider } from '@angular/material/divider';
import { FileUploadService } from "../file-upload/file-upload.service";
import { FileUploadLogEntry, Status } from '../file-upload/file-upload.api';
import { FileUploadDetailsDialogComponent } from "../file-upload-details-dialog/file-upload-details-dialog.component";
import { Page } from "../shared.api";

@Component({
  selector: 'boffice-file-upload-status',
  templateUrl: './file-upload-status.component.html',
  styleUrl: './file-upload-status.component.scss',
  imports: [MatTableModule, MatPaginator, DatePipe, TranslatePipe, MatSort, MatDivider]
})
export class FileUploadStatusComponent {

  reloading: boolean = false
  pageSize: number = 12
  pageIndex: number = 0
  totalSize: number = -1

  displayedColumns = ['start', 'end', 'filename', 'status', 'processedRows', 'errors']
  dataSource: MatTableDataSource<FileUploadLogEntry> = new MatTableDataSource()

  constructor(private service: FileUploadService, private toast: HotToastService, private dialog: MatDialog) {
    interval(10000).pipe(
      switchMap(_ => this.reload(this.pageIndex, this.pageSize)),
      takeUntilDestroyed())
      .subscribe()
  }

  private reload(page: number, size: number) {
    if (this.reloading) return EMPTY;

    this.reloading = true;

    return this.service.search(page, size).pipe(
      tap({
        next: (value) => {
          if (value.body) {
            this.handleData(value.body.entries);
          }
        },
        error: (err) => this.handleError(err),
        complete: () => (this.reloading = false)
      }),
      catchError((err) => {
        this.handleError(err);
        return EMPTY;
      })
    );
  }

  private handleData(d: Page<FileUploadLogEntry>) {
    this.dataSource.data = d.content
    this.pageSize = d.pageable.size
    this.pageIndex = d.pageable.number
    this.totalSize = d.totalSize
    this.reloading = false
  }

  private handleError(_err: any) {
    this.toast.error("Something went wrong")
    this.reloading = false
  }

  handlePaginatorEvent(event: PageEvent) {
    this.reload(event.pageIndex, event.pageSize)
  }

  statusColor(entry: FileUploadLogEntry): string {
    switch (entry.status) {
      case Status.PROCESSING:
        return "orange"
      case Status.DONE:
        return "green"
      case Status.FAILED:
        return "red"
    }
  }

  openErrors(entry: FileUploadLogEntry) {
    if (!entry) return
    this.dialog.open(FileUploadDetailsDialogComponent, {
      width: '50%',
      data: entry
    })
  }

}
