import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { AuthGuardData, createAuthGuard } from "keycloak-angular"
import { PUBLIC_ACCESS } from "./roles"

const isAccessAllowed = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  authData: AuthGuardData
): Promise<boolean | UrlTree> => {
  const { authenticated, keycloak } = authData
  console.log("Authenticated: " + authenticated)

  const requiredRoles = route.data['roles'] ?? []
  console.log("Required Roles: " + JSON.stringify(requiredRoles))


  const publicAccess = requiredRoles.some((role: string) => role === PUBLIC_ACCESS)
  console.log("Public Access: " + publicAccess)

  if (publicAccess && !authenticated) {
    return true
  }

  if (!authenticated) {
    await keycloak.login({
      redirectUri: window.location.origin + state.url
    })
    return false
  }

  if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
    return true
  }

  // let availableRoles = [...Object.values(authData.grantedRoles.resourceRoles), ...Object.values(authData.grantedRoles.realmRoles)]
  let availableRoles = authData.grantedRoles.realmRoles
  console.log("Available Roles: " + JSON.stringify(availableRoles))

  const hasRequiredRole = requiredRoles.some(role => availableRoles.some(roles => roles.includes(role)))
  if (hasRequiredRole) {
    return true
  }

  const router = inject(Router)
  return router.parseUrl('/not-authorized')
}

export const canActivateAuthRole: CanActivateFn = createAuthGuard<CanActivateFn>(isAccessAllowed)
