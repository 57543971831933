import { inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { LoggingService } from './logging/logging.service';
import { Page } from "./shared.api";


// Define an InjectionToken for the API URL
export const BASE_API_URL = new InjectionToken<string>('BASE_API_URL')

@Injectable({
  providedIn: 'root',
})
export abstract class BaseService {
  protected http = inject(HttpClient);
  protected logger = inject(LoggingService);

  api = inject(BASE_API_URL) ?? 'api/'
  protected retryCount = 3;
  protected urlPrefix: string;

  protected constructor(urlPrefix: string) {
    this.urlPrefix = urlPrefix;
  }

  protected getAll<T>(suffix: string = ''): Observable<T[]> {
    const url = this.createUrl(suffix);
    const observable = this.http.get<T[]>(url);
    return this.createPipe(observable, url, 'getAll');
  }

  protected get<T>(suffix: string, params = new HttpParams()): Observable<T> {
    const url = this.createUrl(suffix);
    const observable = this.http.get<T>(url, { params });
    return this.createPipe(observable, url, 'get');
  }

  protected download(suffix: string): Observable<any> {
    const url = this.createUrl(suffix);
    const observable = this.http.get(url, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
    return this.createPipe(observable, url, 'download');
  }

  protected downloadPayload(suffix: string, body: any, params = new HttpParams()): Observable<any> {
    const url = this.createUrl(suffix);
    const observable = this.http.post(url, body, {
      responseType: 'arraybuffer',
      observe: 'response',
      params,
    });
    return this.createPipe(observable, url, 'downloadPayload');
  }

  protected post<T>(suffix: string, body: any, params = new HttpParams()): Observable<T> {
    const url = this.createUrl(suffix);
    const observable = this.http.post<T>(url, body, { params });
    return this.createPipe(observable, url, 'post');
  }

  protected postPaged<T>(suffix: string, body: any, page: number, size: number, params = new HttpParams(), queryParams: string = ''): Observable<Page<T>> {
    const url = this.createUrl(suffix);
    const uri = url + "?page=" + page.toString() + "&size=" + size.toString() + queryParams
    const observable = this.http.post<Page<T>>(uri, body, {
      params
    });
    return this.createPipe(observable, url, 'post');
  }

  protected postResponse<T>(suffix: string, body: any, params = new HttpParams()): Observable<HttpResponse<T>> {
    const url = this.createUrl(suffix);
    const observable = this.http.post<T>(url, body, {
      observe: 'response',
      params
    });
    return this.createPipe(observable, url, 'post', 1);
  }

  protected put<T>(suffix: string, body: any): Observable<T> {
    const url = this.createUrl(suffix);
    const observable = this.http.put<T>(url, body);
    return this.createPipe(observable, url, 'put');
  }

  protected putText<T>(suffix: string, body: any): Observable<string> {
    const url = this.createUrl(suffix);

    const observable = this.http.put(url, body, { responseType: 'text' });
    return this.createPipe(observable, url, 'put');
  }

  protected patch<T>(suffix: string, body: any): Observable<T> {
    const url = this.createUrl(suffix);
    const observable = this.http.patch<T>(url, body);
    return this.createPipe(observable, url, 'patch');
  }

  protected delete<T>(suffix: string): Observable<T> {
    const url = this.createUrl(suffix);
    const observable = this.http.delete<T>(url);
    return this.createPipe(observable, url, 'delete');
  }

  protected getPaged<T>(
    suffix: string,
    page: number,
    size: number,
    params = new HttpParams(),
    queryParams: string = ''
  ): Observable<Page<T>> {
    const url = this.createUrl(suffix);
    const uri = `${ url }?page=${ page }&size=${ size }${ queryParams }`;
    const observable = this.http.get<Page<T>>(uri, { params });
    return this.createPipe(observable, url, 'getPaged');
  }

  private createPipe<T>(
    observable: Observable<T>,
    url: string,
    operation: string,
    count: number = this.retryCount
  ): Observable<T> {
    return observable.pipe(
      retry(count),
      catchError(this.handleError<T>(`${ operation }: ${ url }`)),
      shareReplay(1)
    );
  }

  private handleError<T>(message: string) {
    return (error: any): Observable<T> => {
      this.logger.error(message, error);
      return of(null as T);
    };
  }

  private createUrl(suffix: string): string {
    return suffix ? `${ this.api }${ this.urlPrefix }/${ suffix }` : `${ this.api }${ this.urlPrefix }`;
  }
}
