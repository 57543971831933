import { Component, inject, input } from '@angular/core';
import { Location, AsyncPipe, SlicePipe } from "@angular/common";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { map, shareReplay } from "rxjs/operators";
import { MatToolbar } from '@angular/material/toolbar';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'boffice-header-toolbar',
    templateUrl: './header-toolbar.component.html',
    styleUrls: ['./header-toolbar.component.scss'],
    imports: [MatToolbar, MatIconButton, MatTooltip, MatIcon, AsyncPipe, SlicePipe, TranslatePipe]
})
export class HeaderToolbarComponent {
  private location = inject(Location);
  private responsive = inject(BreakpointObserver);


  readonly showBack = input<boolean>(true);
  readonly title = input<string>();
  readonly subtitleFirst = input<string>();
  readonly subtitleLast = input<string>();
  isHandset$: Observable<boolean> = this.responsive.observe(['(max-width: 1000px)'])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  back() {
    this.location.back()
  }
}
