export const environment = {
  production: false,
  logrocket: true,
  logrocketAppID: 'd1y2gb/bdn-dev',
  embedded: false,
  sapIotBaseUrl: 'https://bdn-qa.cfapps.eu10.hana.ondemand.com',
  googleAnalyticsId: 'G-YRPWB5EZ4C',
  keycloak: {
    url: 'https://auth.dev.mybitzer.de/auth',
    realm: 'bdn',
    clientId: 'mybitzer-backoffice-app',
  },
  availableLanguages: [
    { code: 'en', label: 'English' },
    { code: 'de', label: 'Deutsch' }
  ]
}
