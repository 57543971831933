<table mat-table [dataSource]="dataSource" matSort>

  <ng-container matColumnDef="start">
    <th mat-header-cell *matHeaderCellDef>{{'file-upload.table.start' | translate}}</th>
    <td mat-cell *matCellDef="let statusEntry;"> {{ statusEntry.start | date: 'medium' }}</td>
  </ng-container>

  <ng-container matColumnDef="end">
    <th mat-header-cell *matHeaderCellDef>{{'file-upload.table.end' | translate}}</th>
    <td mat-cell *matCellDef="let statusEntry;"> {{ statusEntry.end | date: 'medium' }}</td>
  </ng-container>

  <ng-container matColumnDef="filename">
    <th mat-header-cell *matHeaderCellDef>{{'file-upload.table.file' | translate}}</th>
    <td mat-cell *matCellDef="let statusEntry;"> {{ statusEntry.filename }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>{{'file-upload.table.status' | translate}}</th>
    <td mat-cell *matCellDef="let statusEntry;" [style.color]="statusColor(statusEntry)"> {{ statusEntry.status }}</td>
  </ng-container>

  <ng-container matColumnDef="processedRows">
    <th mat-header-cell *matHeaderCellDef>{{'file-upload.table.rows' | translate}}</th>
    <td mat-cell *matCellDef="let statusEntry;"> {{ statusEntry.processedRows }}</td>
  </ng-container>

  <ng-container matColumnDef="errors">
    <th mat-header-cell *matHeaderCellDef>{{'file-upload.table.errors' | translate}}</th>
    <td mat-cell *matCellDef="let statusEntry;">
      @if (statusEntry.errors.length > 0) {
        <button (click)="openErrors(statusEntry)">{{ statusEntry.errors.length }} Errors</button>
      }
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="dense-1 hover:bg-stone-100"></tr>
</table>
<mat-divider></mat-divider>
<mat-paginator [length]="totalSize"
               [pageSize]="pageSize"
               [pageIndex]="pageIndex"
               showFirstLastButtons
               [pageSizeOptions]="[14,28,56,112]"
               (page)="handlePaginatorEvent($event)"
               class="dense-1 py-3">
</mat-paginator>
