import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'boffice-board',
  imports: [],
  templateUrl: './board.component.html',
  styleUrl: './board.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoardComponent {

}
