import { Page } from "@mybitzer-workspace/shared";

export interface FileUploadLogSearchResponse {
  entries: Page<FileUploadLogEntry>
}

export interface FileUploadLogEntry {
  id: string,
  start: string,
  end: string,
  filename: string,
  status: Status,
  processedRows: number,
  errors: string[],
}

export enum Status {
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  FAILED = 'FAILED',
}
