<mat-toolbar color="primary" class="gap-2 items-center">
  @if (showBack()) {
    <button mat-icon-button (click)="back()"
      matTooltip="{{'back' | translate}}"
      >
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
  }
  <div style="flex: 0 1 45px;">
    <ng-content select="app-header-toolbar-icon"></ng-content>
  </div>
  <div class="flex flex-col ps-2 gap-1" style="line-height: normal !important;">
    <div>
      <h5>@if (title()) {
        <strong class="text">
          {{ (isHandset$ | async) && title()!.length > 15 ? (title() | slice:0:15)+'...':(title()) }}
        </strong>
      }</h5>
    </div>
    <div class="flex flex-row gap-2">
      <div class="details text">{{subtitleFirst()}}</div>
      @if (subtitleLast()) {
        <div class="details text">
          {{ (isHandset$ | async) && subtitleLast()!.length > 15 ? (subtitleLast() | slice:0:15)+'...':(subtitleLast()) }}
        </div>
      }
    </div>
  </div>


  <div class="flex-auto">
    <ng-content class="ps-5" select="app-header-toolbar-content"></ng-content>
  </div>

  <ng-container>
    <ng-content select="app-header-toolbar-filter"></ng-content>
  </ng-container>
  <div>
    <ng-content select="app-header-toolbar-actions"></ng-content>
  </div>


</mat-toolbar>
