<h1 mat-dialog-title>{{'file-upload.dialog.title' | translate}}</h1>
<mat-divider></mat-divider>
@for (e of data.errors; track e; let last = $last) {
  <div class="flex flex-col gap-2 flex-auto content-stretch ps-3 py-2">
    <div class="flex flex-row gap-2">
      <mat-icon>radio_button_unchecked</mat-icon>
      <span>{{ e }}</span>
    </div>

  </div>
}
<mat-divider></mat-divider>
<div mat-dialog-actions class="float-end p-3">
  <button mat-raised-button color="primary" mat-dialog-close> {{ 'ok' | translate }}</button>
</div>
